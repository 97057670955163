<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row>

            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('title')"
                vid='title'
                rules='required'
              >
                <b-form-group
                  :label="$t('title')"
                >
                  <b-form-input
                    v-model='form.title'
                    :placeholder="$t('title')"
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('body')"
                vid='body'
                rules='required'
              >
                <b-form-group
                  :label="$t('body')"
                >
                  <b-form-textarea
                    v-model='form.body'
                    :placeholder="$t('body')"
                    autocomplete='off'
                    rows='5'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='packages'
                :name="$t('packages')"
              >
                <b-form-group
                  :label="$t('packages')"
                >
                  <v-select
                    v-model='form.packages'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    multiple
                    :options='packages'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='types'
                :name="$t('type')"
              >
                <b-form-group
                  :label="$t('type')"
                >
                  <v-select
                    v-model='type'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='types'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              md='6'
              v-if='type =="page"'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='pages'
                :name="$t('page')"
              >
                <b-form-group
                  :label="$t('page')"
                >
                  <v-select
                    v-model='form.page'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='pages'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
              v-if='type =="link"'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('link')"
                vid='link'
                rules='required'
              >
                <b-form-group
                  :label="$t('link')"
                >
                  <b-form-input
                    v-model='form.link'
                    :placeholder="$t('link')"
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
               >
                <ImagePreview
                  v-model='image'
                  :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [formMixin],
  data() {
    return {
      image: null,
      packages: [],
      types: [{
        id: 'link',
        name: this.$t('link'),
      },
        {
          id: 'page',
          name: this.$t('page'),
        },
      ],
      type: null,

      pages: [
        {
          id: '/subscriptions',
          name: this.$t('packages'),
        },
        {
          id: '/profile',
          name: this.$t('Profile'),
        },
      ],
      form: {
        page: null,
        title: null,
        body: null,
        image: null,
        link: null,
        packages: [],
      },

      isDataLoaded: false,
    }
  },
  created() {
    this.axios.get(`${websiteMainUrl()}/model-list/packages-list`)
      .then(res => {
        this.packages = res.data.data
      })
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },

    type(val) {
      this.form.link = null
      this.form.page = null
    },
  },

  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            body: data.body,
            packages: data.packages,
          }
          this.image = data.image_path

          this.isDataLoaded = true
        })
    },
  },

}
</script>
